.tag{
  outline: none;
  border: none;
  color: black;
  margin: 0px 10px;
  font-size: 1.2rem;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.active{
  color: #81d4fa  ;
  font-weight: bold;
  border-bottom:2px solid black;
}